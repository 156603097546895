iframe{
  display:none
}
.App,body {
  background-color: #59acd9;
  height: 100%;
  text-align: center;
  color: white;
  background-image: linear-gradient( 117deg, #59acd9 11.2%, rgb(128 255 221 / 50%) 57.8%, rgb(165 0 255 / 41%) 85.9% );
}

p {
  margin:0;
}

 .container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
}

.header-container {
  padding-top: 3px;
}

header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 10px 10px 10px;
}

.title {
  margin: 0;
  font-size: 25px;
  font-weight: bold;
  color: white;
}

.subtitle {
  font-size: 24px;
  color: white;
}

.left{
  text-align: left;
  margin-left: 10%;
}

.logo{
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.right{
  display: flex;
  background: black;
  border-radius: 16px;
  padding: 12px 20px;
  height: 99%;
  margin-right: 10%;
  margin-top: 10px;
}

.content-container {
  background-color: #a200d6;
}

.cta-button {
  height: 45px;
  border: 0;
  width: auto;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  color: white;
}

.connect-wallet-container {
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 550px;
  padding-top: 50px
}

.connect-wallet-container img {
  padding-bottom: 20px;
}

.connect-wallet-button {
  background-image: linear-gradient(
    to right,
    #ff8177 0%,
    #ff867a 0%,
    #ff8c7f 21%,
    #f99185 52%,
    #cf556c 78%,
    #b12a5b 100%
  );
  background-size: 200% 200%;
  animation: gradient-animation 4s ease infinite;
}

.mint-button {
  background: -webkit-linear-gradient(left, #a200d6, #ff6fdf);
  font-size: 18px;
  background-size: 200% 200%;
  animation: gradient-animation 4s ease infinite;
  margin: 20px 10px;
  border-radius: 20px;
  padding: 0px 2rem;
}

.mint-button:disabled{
  background: #550072;
  color: rgb(209, 209, 209);
  cursor: not-allowed;
}

.mint-row{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.edit-button{
  border-radius: 50%;
  cursor: pointer;
  margin-left: 3px;
  top: 20px;
  border: 0px;
  font-size: 12px;
  background-color: transparent;
}

.edit-icon{
  width: 12px;
}


.footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
}

.twitter-logo {
  width: 35px;
  height: 35px;
}

.footer-text {
  color: white;
  font-size: 16px;
  font-weight: bold;
}

.form-container{
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10%;
}

input{
  border: 0;
  border-radius: 12px;
  background-color: black;
  color: white;
  letter-spacing: 1px;
  font-size: 26px;
  width: 400px;
  margin-bottom: 10px;
  padding: 10px 20px;
  /* text-align: center; */
}

input::placeholder {
  color: gray;
  letter-spacing: 2px;
  text-align: center !important;
}

input:focus {
  outline: none;
}

.first-row{
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

}

.tld {
  position: absolute;
  font-size: 20px;
  font-weight: bold;
  color: white;
  /* left: -70px; */
  right: 22px;
  margin: 0;  
  padding: 0;
  margin-bottom: 13px;
}

.mint-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.mint-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  border-radius: 5px;
  margin: 10px 15px;
  font-size: 18px;
  background-color: #c4b7fa;
  color: black;
  min-width: 150px;
  max-width: fit-content;
  box-shadow: 0px 0px 10px 3px rgba(255, 255, 255, 0.2);
}

/* Different background-colour for every nth mint-item */
.mint-item:nth-child(2n) {
  background-color: lightblue;
}

.mint-item:nth-child(3n) {
  background-color: lightpink;
}

.mint-item:nth-child(5n) {
  background-color: lightgreen;
}

.link{
  color: black;
  text-decoration: none;
}

.underlined{
  text-decoration: underline;
}

.underlined:hover{
  cursor: pointer;
}

/* KeyFrames */
@-webkit-keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

html,
body {
  height: 100%;
}
.loader{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(0 0 0 / 35%);
  z-index: 2;
  cursor: progress;
}
.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  width: 50px;
  height: 50px;
}
.spinner .path {
  stroke: #a500ff;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}


@media screen and (max-width:1024px){
  html, body, .container{
    width:100%;
  }
  header{
    flex-direction: column;
  }
  .left {
    text-align: center;
    margin: auto !important;
}
  .right {
    margin-right: 0px;
    justify-content: center;
}
.connect-wallet-container img{
  height: 200px
}
.connect-wallet-container button{
  margin-top: 35px;
}
}

.price-span{
  font-size: 20px;
  color: #fff;
}

@media screen and (max-width:6500px){
  header{
    align-items: center;
  }
  .subtitle{
    font-size:24px;
    font-weight: 600;
  }
  .logo{
    width: 60px;
    height: 60px;
  }
  .right{
    align-items: center;
    font-weight: bold;
    background: transparent;
    background-image: linear-gradient( 149deg, #59acd9 11.2%, rgb(128 255 221 / 50%) 57.8%, rgb(165 0 255 / 41%) 85.9% );
    box-shadow: 0px 0px 10px 3px rgb(255 255 255 / 12%);
  }
  .right p{
    font-size:22px;
  }
  .right a{
    color: #fff;
    font-size:22px;
  }
  input{
    width: 320px;
    background:transparent;
    box-shadow: 0px 0px 10px 3px rgb(255 255 255 / 12%);
    /* background-image: linear-gradient( 149deg, #59acd9 11.2%, rgb(128 255 221 / 50%) 57.8%, rgb(165 0 255 / 41%) 85.9% ); */
    background-image: linear-gradient( 149deg, #59acd9 11.2%, rgb(0 255 187 / 55%) 57.8%, rgb(165 0 255 / 41%) 85.9% );
  }
  .edit-icon {
    width: 20px;
}
  .tld{
    font-size: 22px;
  }
  .link, .mint-item p{
    color: #152c06d9;
    font-weight: 400;
    font-size: 22px;
  }
  .mint-item{
    background-image: linear-gradient( 336deg, #59acd9 11.2%, rgb(128 255 221 / 50%) 57.8%, rgb(165 0 255 / 20%) 85.9% );
  }
  /* .modal-class{
    color: black;
  } */
  .modal-class>div{
    height:150px;
    background-image: linear-gradient( 149deg, #59acd9 11.2%, rgb(128 255 221 / 50%) 57.8%, rgb(165 0 255 / 41%) 85.9% ) !important;
    border-radius: 15px;
  }
  .modal-class>div div{
    height: 150px;
    margin: auto !important;
    align-items: center;
    justify-content: center;
    display: flex;
    /* color: #000000b8; */
    font-weight: 600;
    font-size: 22px;
    background: -webkit-linear-gradient(#366f87, #272d42);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  } 
  .modal-class>div div a{
    text-decoration: underline;
    text-decoration-color: #d996ff !important;
    
  }
  ul canvas{
    z-index: 100000 !important;
  }
}

[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}
[data-title]:after {
  content: attr(data-title);
  position: absolute;
  top: 1em;
  left: 50%;
  padding: 4px 4px 4px 8px;
  color: #fff;
  white-space: nowrap; 
  -moz-border-radius: 5px; 
  -webkit-border-radius: 5px;  
  border-radius: 5px;  
  -moz-box-shadow: 0px 0px 4px #222;  
  -webkit-box-shadow: 0px 0px 4px #222;  
  box-shadow: 0px 0px 4px #222;  
  background-image: -moz-linear-gradient(top, #f8f8f8, #cccccc);  
  background-image: -webkit-gradient(linear,left top,left bottom,color-stop(0, #f8f8f8),color-stop(1, #cccccc));
  background-image: -webkit-linear-gradient(top, #f8f8f8, #cccccc);  
  background-image: -moz-linear-gradient(top, #f8f8f8, #cccccc);  
  background-image: -ms-linear-gradient(top, #f8f8f8, #cccccc);  
  background-image: -o-linear-gradient(top, #f8f8f8, #cccccc);
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
}
[data-title] {
  position: relative;
}

.modal-class{
  position: fixed;
  font-family: Arial, Helvetica, sans-serif;
  inset: 0px;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99999;
  transition: opacity 1s ease-in 0s;
  pointer-events: auto;
  overflow-y: auto;
}
.test{
  width: 400px;
    position: relative;
    margin: 10% auto;
    padding: 5px 20px 13px;
    background: rgb(255, 255, 255);
}